import * as types from './Types'

const initialState = {
    loadedPinCode: {},
    selectedProduct: {},
    AllOccupation: {},
    AllEducation: [],
    MemberOccupation: {},
    getAllRelations: {},
    getAllState: {},
    getCityBasedOnState: [],
    PolicyDetails: {},
    familyDetails: {},
    loadQuotes: {},
    sotoreAddQuotes: {},
    getMedical: [],
    proposerData: {},
    insuredMember: {},
    CommAddress: {},
    proposerRes: {},
    coverBenefit: {},
    nivabhupaKycResp: {},
    NivabhupaValue: {},
    nivaBhupaAddons: {},
    addOns: {},
    getNomineeRelations: [],
    SbiKyc: [],
    CareKycRes: [],
    IciciKycRes: [],
    HdfcKycRes: [],
    genricOccupation: [],
    getGenericKycRes: [],
    loading: true
}

export const HealthReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.LOAD_ALL_PINCODE: return {
            ...state,
            loadedPinCode: payload,
            loading: false
        }
        case types.GET_HEALTH_QUOTES: return {
            ...state,
            loadQuotes: payload,
            loading: false
        }
        case types.FAMILY_STORE: return {
            ...state,
            familyDetails: payload,
            loading: false
        }
        case types.GET_ALL_GENRIC_OCCUPATIONS: return {
            ...state,
            genricOccupation: payload,
            loading: false
        }
        case types.HEALTH_QUOTES_STORE: return {
            ...state,
            sotoreAddQuotes: payload,
            loading: true
        }
        case types.SELECTED_PRODUCT_ADDED: return {
            ...state,
            selectedProduct: payload,
            loading: false
        }
        case types.ADDONS_STORE: return {
            ...state,
            addOns: payload,
            loading: false
        }
        // Nivabhupa
        case types.NIVABHUPA_QUOTES_VALUE: return {
            ...state,
            NivabhupaValue: payload,
            loading: false
        }
        case types.NIVABHUPA_KYC_RESPONSE_STORE: return {
            ...state,
            nivabhupaKycResp: payload,
            loading: false
        }
        case types.NIVABHUPA_ADDONS: return {
            ...state,
            nivaBhupaAddons: payload,
            loading: false
        }
        // End Stored Data 
        case types.EMPTY_NIVABHUPA_DATA:
            return {
                ...state,
                nivabhupaKycResp: null,
                NivabhupaValue: initialState.NivabhupaValue,
                coverBenefit: initialState.coverBenefit,
                addOns: null
            }

        case types.GET_HEALTH_PROPOSAL: return {
            ...state,
            proposerRes: payload,
            loading: false
        }
        case types.POLICY_DETAILS_ADDED: return {
            ...state,
            PolicyDetails: payload,
            loading: false
        }
        case types.COVERED_BENEFITS: return {
            ...state,
            coverBenefit: payload,
            loading: false
        }
        case types.GET_ALL_MEMBER_OCCUPATION: return {
            ...state,
            MemberOccupation: payload,
            loading: false
        }
        case types.GET_ALL_OCCUPATIONS: return {
            ...state,
            AllOccupation: payload,
            loading: false
        }
        case types.GET_ALL_MEMBER_EDUCATION: return {
            ...state,
            AllEducation: payload,
            loading: false
        }
        case types.GET_ALL_MEDICAL: return {
            ...state,
            getMedical: payload,
            loading: false
        }
        case types.GET_ALL_RELATION: return {
            ...state,
            getAllRelations: payload,
            loading: false
        }
        case types.GET_NOMINEE_RELATIONS_INSURERID: return {
            ...state,
            getNomineeRelations: payload,
            loading: false
        }
        case types.GET_ALL_STATE: return {
            ...state,
            getAllState: payload,
            loading: false
        }
        case types.GET_ALL_CITY: return {
            ...state,
            getCityBasedOnState: payload,
            loading: false
        }
        // Stored Data 
        case types.PROPOSER_DETAILS: return {
            ...state,
            proposerData: payload,
            loading: false
        }
        case types.INSURED_MEMBER: return {
            ...state,
            insuredMember: payload,
            loading: false
        }
        case types.COMMUNICATION_ADDRES: return {
            ...state,
            CommAddress: payload,
            loading: false
        }
        case types.SBI_KYC_RES: return {
            ...state,
            SbiKyc: payload,
            loading: false
        }
        case types.CARE_KYC_RES: return {
            ...state,
            CareKycRes: payload,
            loading: false
        }
        case types.ICICI_KYC_RES: return {
            ...state,
            IciciKycRes: payload,
            loading: false
        }
        case types.HDFC_KYC_RES: return {
            ...state,
            HdfcKycRes: payload,
            loading: false
        }
        case types.GENERIC_KYC_RESP: return {
            ...state,
            getGenericKycRes: payload,
            loading: false
        }

        case types.EMPTY_LOCAL_SORE:
            return {
                ...state,
                loadedPinCode: initialState,
                loadQuotes: null
            }

        // Proposal All Data Empty
        case types.PROPOSAL_ALL_DATA_EMPTY: return {
            ...state,
            loading: false,
            // proposerRes: initialState,
            nivabhupaKycResp: null,
            getGenericKycRes: null,
            proposerData: null,
            insuredMember: null,
            CommAddress: null,
            SbiKyc: null,
            CareKycRes: null,
            IciciKycRes: null,
            HdfcKycRes: null,
            getCityBasedOnState: initialState.getCityBasedOnState
        }

        case types.STATE_CHANGE_EMPTY_CITY: return {
            ...state,
            getCityBasedOnState: initialState.getCityBasedOnState
        }
        case types.PROPOSAL_LOCAL_SORE: return {
            ...state,
            loading: false,
            // proposerRes: null,
            proposerData: null,
            insuredMember: null,
            CommAddress: null
        }

        default:
            return state
    }
}

export default HealthReducers